<template>
  <div class="">
    <section class="content">
      <div class="container-fluid">
        <!-- Main row -->
        <div class="row">
          <div class="col-md-12 text-center p-3" v-if="pageLoading">
            <i class="fa fa-circle-notch fa-spin fa-2x"></i>
          </div>
          <section class="col-lg-12 connectedSortable table-area mt-3 pr-0 pl-0 mb-3" v-else>
            <div class="card">
              <div class="card-body  table-responsive table-head">
                <div class="row bor-bot pb-3">
                  <div class="col-md-8 pl-0">
                    <h1 class="m-0 text-dark pt-2">Membership Pricing</h1>
                  </div>
                  <div class="col-md-4">
                    <div class="text-right pb-3">
                      <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item"><a href="/admin/dashboard">Home</a></li>
                        <li class="breadcrumb-item">
                          <Breadcrumbs/>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 mb-2 mt-2 text-right">
                    <button class="btn btn-primary btn-darkblue" style="width: auto;" @click="addRow">
                      <i class="fa fa-plus-circle"></i> Add Fee
                    </button>
                  </div>
                  <div class="col-md-12">
                    <div class="table-responsive mt-3">
                      <table class="table table-sm table-bordered table-hover" id="list_table">
                        <thead>
                        <tr>
                          <th>Member Type</th>
                          <th>Fee</th>
                          <th>Start Date</th>
                          <th>End Date</th>
                          <th>Status</th>
                          <th>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                          <!-- Data will be placed here -->
                          <tr>
                            <td colspan="9">&nbsp;</td>
                          </tr>
                          <tr>
                            <td colspan="9">&nbsp;</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
    <AddPrice :editData="editData" @pricingAdded="priceAdded"/>
    <custom-alert v-if="displayAlert===true" id="alert_box" :message="alertMessage"></custom-alert>
  </div>
</template>

<script>
import CustomAlert from "../../CustomAlert";
import axios from 'axios';
import AddPrice from "./AddPrice";
import $ from "jquery";
import "datatables.net-responsive-dt/js/responsive.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";

export default {
  name: "PricingList",
  components: {AddPrice, CustomAlert},
  data() {
    return {
      displayAlert: false,
      alertMessage: "",
      pageLoading: false,
      prices: [],
      membership: "",
      editData: {},
      table: null
    };
  },
  methods: {
    fetchData() {
      this.pageLoading = false;
      let self = this;
      this.table = $("#list_table").DataTable({
        dom: "lfrtip",
        pageLength: 25,
        processing: true,
        serverSide: true,
        bFilter: true,
        responsive: true,
        ajax: {
          url: process.env.VUE_APP_URL + "api/admin/pricing/list",
          type: 'get',
          headers: this.adminHeaders
        },
        columns: [
          {
            data: 'membership_name', name: 'membership.name', width: "30%", render: (data) => {
              return data || "N/A";
            }
          },
          {
            data: 'amount', name: 'amount', render: (data) => {
              return '$'+data || "N/A";
            }
          },
          {
            data: 'formatted_start_date', name: 'start_date', render: (data) => {
              return data || "N/A";
            }
          },
          {
            data: 'formatted_end_date', name: 'end_date', render: (data) => {
              return data || "N/A";
            }
          },
          {
            data: 'formatted_status', name: 'status', render: (data) => {
              return data || "N/A";
            }
          },
          {
            data: 'actions', name: 'actions', render: (data,type,row,meta) => {
              return `
                  <div class="text-left py-0 align-middle">
                      <div class="btn-group btn-group-sm">
                        <button
                            class="btn btn-primary ml-1 editRow"
                            data-id="${row.id}"
                        >
                          <i class="fas fa-pencil-alt"></i>
                        </button>
                        <button
                            class="btn btn-danger ml-1 deleteRow"
                            data-id="${row.id}"
                        >
                          <i class="fas fa-trash"></i>
                        </button>
                      </div>
                  </div>`;
            }
          },
        ],
        order: [[0, "asc"]]
      });

      $(document).on("click", ".editRow", function(e){
        self.editRow($(this).data("id"), e);
      });
      $(document).on("click", ".deleteRow", function(e){
        self.deleteRow($(this).data("id"), e);
      });
    },
    addRow() {
      this.editData = {};
      this.$root.$emit('bv::show::modal', 'add-price-modal');
    },
    priceAdded() {
      this.alertMessage = "New Price has been added";
      this.displayAlert = true;
      this.table.draw();
    },
    editRow(id, target) {
      this.editData = {id: id};
      this.$root.$emit('bv::show::modal', 'add-price-modal', target);
    },
    deleteRow(id) {
      if (confirm("Are you sure to delete this price ?")) {
        axios.delete(this.basePath + "api/admin/pricing/" + id, {headers: this.adminHeaders})
            .then(response => {
              this.alertMessage = "Price is deleted";
              this.displayAlert = true;
              this.table.draw();
            })
            .catch(err => {
              console.log(err);
            });
      }
    }
  },
  mounted() {
    this.fetchData();
  }
}
</script>

<style scoped>

</style>